import React from "react";
import '../Section.css';
import { Container, Row, Col } from 'react-bootstrap';
import Img1 from '../Assests/Images/g1.png';
import Img2 from '../Assests/Images/g2.png'
import Img3 from '../Assests/Images/g3.jpeg'
import Img4 from '../Assests/Images/g4.jpg'
import Img5 from '../Assests/Images/g5.jpeg'
import Img6 from '../Assests/Images/g6.jpeg'

function Section() {
    return (
        <Container>
            <div className="Ourservice">
           <h2> Ourservice </h2>
            </div>
            <Row>
                <Col md={4}>
<div class = "frame">
			<img src = {Img1} alt="#" />
			<div class = "details">
				<h3>Attachment</h3>
				<p>Online communities are increasingly important to organizations and the general public, but there is little theoretically based research on what makes some online 
                    </p>
			</div>
		</div>
        </Col>

        <Col md={4}>
<div class = "frame">
			<img src = {Img2} alt="#" />
			<div class = "details">
				<h3>Team-work</h3>
				<p>Teamwork is the collaborative effort of a group to achieve a common goal or to complete a task in the most effective and efficient way</p>
			</div>
		</div>
        </Col>

        <Col md={4}>
<div class = "frame">
			<img src = {Img3} alt="#" />
			<div class = "details">
				<h3>Team</h3>
				<p>Team members need to learn how to help one another, help other team members realize their true potential, and create an environment that allows everyone. </p>
			</div>
		</div>
        </Col>

        <Col md={4}>
<div class = "frame">
			<img src = {Img4} alt="#" />
			<div class = "details">
				<h3>Success</h3>
				<p>Employee motivation is an intrinsic and internal drive to put forth the necessary effort and action towards work-related activities. It has been broadly defined 
                    </p>
			</div>
		</div>
        </Col>

        <Col md={4}>
<div class = "frame">
			<img src = {Img5} alt="#" />
			<div class = "details">
				<h3>Learning</h3>
				<p>Learning is the process of acquiring new understanding, knowledge, behaviors, skills, values, attitudes, and preferences.</p>
			</div>
		</div>
        </Col>

        <Col md={4}>
<div class = "frame">
			<img src = {Img6} alt="#" />
			<div class = "details">
				<h3>Employees</h3>
				<p>An employee contributes labor and expertise to an endeavor of an employer or of a person conducting a business or undertaking (PCB)[2] and is usually hired to perform
                    </p>
			</div>
		</div>
        </Col>

		</Row>
        </Container>

      

    );
}
export default Section