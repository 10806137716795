import React from "react";
import './Text.css';
 import {Container,Row,Col} from 'react-bootstrap';


function Text() {
    return (
        <div className="texts">
            <Container>
                <div className="hing">
                    <h2> What we can do.? </h2>
                </div>

                <div className="txtgrd">

                    <Row>
                        <Col md={4}>
                           <h4 className="sty">Airline Financial Support Services: </h4>
                           <p className="sty1"> Tata Consultancy Services (TCS) is an Indian multinational information technology (IT) services and consulting company headquartered in Mumbai, Maharashtra, India[6][7] with its largest campus located in Chennai, Tamil Nadu, India. As of February 2021, TCS is the largest 
                               IT services company in the world by market capitalisation ($200 billion).</p>
                        </Col>

                        <Col md={4}>
                            <h4 className="sty">Phoenix Global Solutions: </h4>
                            <p className="sty1">In 2016–2017, parent company Tata Sons owned 72.05% of TCS[19] and more than 70% of Tata Sons' dividends were generated by TCS.[20] In March 2018, Tata Sons decided to sell stocks of TCS worth $1.25 billion in a bulk deal.[21] As of 15 September 2021, TCS has recorded a market
                                 capitalisation of US$200 billion, making it the first Indian IT firm to do so.[22 </p>
                        </Col>
                        <Col md={4}>
                            <h4 className="sty">Supervalu Services India :</h4>
                            <p className="sty1"> In May 2013, TCS was awarded a six-year contract worth over ₹11 billion (US$150 million) to provide services to the Indian Department of Posts.[39] In 2013, the firm moved from the 13th position to 10th position in the League of top 10 global IT services companies[40] and in July 2014, it became the first Indian company
                                 with over ₹5 trillion (equivalent to ₹6.8 trillion or US$90 billion in 2020) market capitalisation</p>
                        </Col>
                    </Row>

                </div>
            </Container>
        </div>
    );
}

export default Text