import React from "react";
import './Carrerban.css'
import Suces from '../Assests/Images/successpic.png';

function Carrerban() {

    return(
      <div className="suc">

      <div className="success">
   <img 
        src={Suces}
        alt="##"
        width="100%"
        height="570px"
        />
          </div>
          <div className="suctext">
            <h2 className="sss">Success:</h2>
            <h5 className="ppp">
            Success is the state or condition of meeting a defined range of expectations<br />
            It may be viewed as the opposite of failure. The criteria for success
            </h5>
          </div>
</div>
    );
}
export default Carrerban