import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// import Nav from './Component/Nav.js';
import { Routes, Route } from 'react-router-dom';
import NavBar from './Component/NavBar.js';
import Home from './Component/Home.js';
import About from './Component/About.js';
// import Text from './Component/Text.js';
import Footer from './Component/Footer.js';
import Carrer from './Component/Carrer.js';
import Contact from './Component/Contact.js';

function App() {
  return (
    <div className="App">
      
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/About' element={<About />} />
        <Route path='/Carrer' element={<Carrer />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
      <Footer />

    </div>
  );
}

export default App;