import React from "react";
import Contactform from './Contactform'

const Carrer = () => {
return(
  <div>
<Contactform/>
      </div>
)
}
export default Carrer