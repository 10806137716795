import React from "react";
import './Aboutgrid.css'
import { Container, Row, Col } from 'react-bootstrap';


function Aboutgrid() {
    return (
        <div className="abtgrid">
            <Container>
                <div className="Company">
                    <h3> Company </h3>
                    </div>
                <Row>
                    <Col md={4}>
                        <h3 className="abtgd1">Employment </h3>
                        <p> Employment is the relationship between two parties, usually based on a contract where work is paid for, where one party, which may be a corporation, for profit, not-for-profit organization, co-operative or other entity is the employer and the other is the employee.[1]
                            in return for payment, which may be in the form of an hourly wage, by piecework or an annual salary.</p>
                    </Col>
                    <Col md={4}>
                        <h3 className="abtgd1">Employers </h3>
                        <p>  An employee contributes labor and expertise to an endeavor of an employer or of a person conducting a business or undertaking (PCB)[2] and is usually hired to perform specific duties which are packaged into a job. In a corporate context, an employee is a person who is hired to provide services to a company on a regular basis in
                            exchange for compensation and who does not provide.</p>
                    </Col>
                    <Col md={4}>
                        <h3 className="abtgd1"> Employee benefits</h3>
                        <p> Employee benefits are various non-wage compensation provided to employees in addition to their wages or salaries. The benefits can include: housing (employer-provided or employer-paid), group insurance (health, dental, life etc.), disability income protection, retirement benefits, daycare, tuition reimbursement, sick leave, vacation (paid and non-paid).
                         </p>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}
export default Aboutgrid