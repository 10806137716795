import React from "react";
import Banner from "./Banner";
import Section from "./Section";
import Text from "./Text";


const Home = () => {
    return (
        <div>
            <Banner />
            <Section />
            <Text />
        </div>
    )
}

export default Home