import React from "react";
import './Carreracc.css'
import {Accordion, } from 'react-bootstrap'

function Carreracc() {
return (
    <div className="accordian">

      <div className="Success">
             <h2> Success </h2>
          </div>

<Accordion defaultActiveKey="0" flush>
  <Accordion.Item eventKey="0">
    <Accordion.Header>Success</Accordion.Header>
    <Accordion.Body>
    Success is the state or condition of meeting a defined range of expectations. It may be viewed as the 
    opposite of failure. The criteria for success depend on context, and may be relative to a particular
     observer or belief system. One person might consider a success what another person considers a 
     failure, particularly in cases of direct competition or a zero-sum game. Similarly, the degree of 
     success or failure in a situation may be differently viewed by distinct observers or participants,
      such that a situation that one considers to be a success, another might consider to be a failure,
       a qualified success or a neutral situation. For example, a film that is a commercial failure or 
       even a box-office bomb can go on to receive a cult following, with the initial lack of commercial 
        lending a cachet of subcultural coolness.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Teamwork</Accordion.Header>
    <Accordion.Body>
    Teamwork is the collaborative effort of a group to achieve a common goal or to complete a task in
     the most effective and efficient way.[1][2] This concept is seen within the greater framework of
      a team, which is a group of interdependent individuals who work together towards a common goal.
      [3] The four key characteristics of a team include a shared goal, interdependence, boundedness 
      and stability, the ability to manage their own work and internal process, and operate in a
       bigger social system. [4] Basic requirements for effective teamwork are an adequate team size.
        The context is important, and team sizes can vary depending upon the objective. A team must
         include at least 2 or more members, and most teams range in size from 2 to 100. Sports teams 
         generally have fixed sizes based upon set rules, and work teams may change in size depending 
         upon the phase and complexity of the objective. Teams need to be able to leverage resources t
         o be productive (i.e. playing fields or meeting spaces, scheduled times for planning, guidanc
         e from coaches or supervisors, support from the organization, etc.),
    </Accordion.Body>
  </Accordion.Item>
</Accordion>

    </div>
);
}
export default Carreracc