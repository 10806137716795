import React from "react";
import './Contactform.css'
import {InputGroup,Button,FormControl, Container} from "react-bootstrap";

function Contactform() {
return (
    <div className="bkpic">
<Container>
    <div className="cfm">
     <h2> Contact Form:</h2>
        </div>

        <div className="gform">
<>
  <InputGroup className="mb-3">
    <Button variant="outline-secondary" id="button-addon1">
      Name
    </Button>
    <FormControl
      aria-label="Example text with button addon"
      aria-describedby="basic-addon1"
    />
  </InputGroup>

  <InputGroup className="mb-3">
    <FormControl
      placeholder="Recipient's username"
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />
    <Button variant="outline-secondary" id="button-addon2">
      Click Here
    </Button>
  </InputGroup>

  <InputGroup className="mb-3">
    <Button variant="outline-secondary">Address</Button>
    <FormControl aria-label="Example text with two button addons" />
  </InputGroup>

  <InputGroup>
    <FormControl
      placeholder="Recipient's username"
      aria-label="Recipient's username with two button addons"
    />
    <Button variant="outline-secondary">Click Here</Button>
  </InputGroup>
</>
</div>
</Container>
    </div>
);
}
export default Contactform