import React from 'react'
import { NavLink } from 'react-router-dom';
import './NavBarss.css';
import {Container, Navbar, Nav,} from 'react-bootstrap';


const NavBar = () => {
    return (
        <div className="header">
          <Container className="ContainerHeader">
           <Navbar expand="lg">
             <div className="logo">
               <NavLink to ='/'>
               <img src ="./logo192.png" alt='#' />
               </NavLink>
             </div>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto nav-links">
      <NavLink exact activeClassName='active_class' to = "/" className='menu'>Home</NavLink>
      <NavLink exact activeClassName='active_class' to = "/About" className='menu'>About</NavLink>
      <NavLink exact activeClassName='active_class' to = "/Carrer" className='menu'>Carrer</NavLink>
      <NavLink exact activeClassName='active_class' to = "/Contact" className='menu'>Contact</NavLink>
       
     </Nav>
  

  </Navbar.Collapse>
</Navbar>
</Container>
        </div>
    )
}

export default NavBar