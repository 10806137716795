import React from "react";
import Aboutbanner from "./Aboutbanner";
import Aboutgrid from "./Aboutgrid";


const About = () => {
    return (
        <div>
<Aboutbanner />
<Aboutgrid />
        </div>
    )
}

export default About