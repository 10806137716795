import React from "react";  
import { Carousel } from "react-bootstrap";
import Picss1 from '../Assests/Images/brain-banner.jpg';
import Picss2 from '../Assests/Images/brain.jpg';
import Picss3 from '../Assests/Images/brain2.jpg';

function Banner() {
    return (
        <div className="pic">
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Picss1}
                        alt="First slide"
                        width="100%"
                    />
                    <Carousel.Caption>
                        <h3>Knowledge is Divine</h3>
                        <p>Situated knowledge is knowledge specific to a particular situation.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Picss2}
                        alt="Second slide"
                        width="100%"
                        height="570px"
                    />

                    <Carousel.Caption>
                        <h3>Knowledge is Power</h3>
                        <p>Situated knowledge is knowledge specific to a particular situation.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Picss3}
                        alt="Third slide"
                        width="100%"
                        height="570px"
                    />

                    <Carousel.Caption>
                        <h3>Knowledge is Important</h3>
                        <p>Situated knowledge is knowledge specific to a particular situation.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div >
    );
}

export default Banner