import React from "react";
import './Aboutbanner.css'
import Abtppic from "../Assests/Images/targetabt2.jpg";

function Aboutbanner() {
return (
<div className="About">
<div className="abt">
    <img 
       src={Abtppic}
       alt="##"
       width="100%"
       height="570px"
       />
    </div>
     
     <div className="abttext">
<h1 className="st"> Target:</h1>
<h5> Target is the main important thing that every employee should have. </h5>
     </div>
</div>
);
}
export default Aboutbanner