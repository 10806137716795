import React from "react";
import './Footer.css'
import {Container,Row,Col} from 'react-bootstrap';


function Footer() {
    return (
        <div className="foot">
            <Container>
                <div className="fff">
            <Row >
                <Col md={3}>
                    <h4 className="f1">About: </h4>
                    <p > Tata Consultancy Services (TCS) is an Indian multinational information technology (IT) services and consulting company headquartered in Mumbai, Maharashtra, India[6][7] with its largest campus located in Chennai, Tamil Nadu, India. As of February 2021, TCS is the largest
                        IT services company in the world by market capitalisation ($200 billion).</p>
                </Col>

                <Col md={3}>
                    <h4 className="f1">Our: </h4>
                    <p >
                        <ul className="link">
                           <li> Home </li>
                           <li> About </li>
                           <li> Career</li>
                           <li> Success</li>
                           <li> Contact</li>
                            </ul>
                    </p>
                </Col>
                <Col md={3}>
                    <h4 className="f1">Company:</h4>
                    <p > In May 2013, TCS was awarded a six-year contract worth over ₹11 billion (US$150 million) to provide services to the Indian Department of Posts.[39] In 2013, the firm moved from the 13th position to 10th position in the League of top 10 global IT services companies[40] and in July 2014, it became the first Indian company
                        with over ₹5 trillion (equivalent to ₹6.8 trillion or US$90 billion in 2020) market capitalisation</p>
                </Col>

                <Col md={3}>
                    <h4 className="f1">Contact:</h4> 
                    <li> 9966069497 </li>
                    <li> 9963532689 </li>
                      </Col>
            </Row>
            </div>
            </Container>
        </div>


    );
}

export default Footer