import React from "react";
import Carreracc from "./Carreracc";
import Carrerban from "./Carrerban";

const Carrer = () => {

return(
<div>
<Carrerban />
<Carreracc />
</div>

)
}
export default Carrer